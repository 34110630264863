import React from "react";
import PropTypes from "prop-types";
import { graphql, navigate } from "gatsby";
import { Helmet } from "react-helmet";

import { Layout } from "../components/common";
import { MetaData } from "../components/common/meta";

/**
 * Single page (/:slug)
 *
 * This file renders a single page and loads all the content.
 *
 */
const Issues = ({ data, location }) => {
    // const tags =
    const issues = data.allGhostTag.nodes.filter((t) => {
        return t.slug.includes("issue");
    });

    issues.sort(
        (a, b) =>
        parseInt(a.slug.split("-").pop()) - parseInt(b.slug.split("-").pop())
            
    );

    return (
        <>
            <MetaData data={data} location={location} type="website" />
            <Helmet>
                {/* <style type="text/css">{`${page.codeinjection_styles}`}</style> */}
            </Helmet>
            <Layout flex bg={"rgb(248,249,250)"}>
                <style>
                    {`
                        .issues-container {
                            background: rgb(248,249,250);
                            display: flex;
                            flex: 1;
                            flex-direction: column;
                            justify-content: center;
                        }

                        .issues {
                            display: grid;
                            grid-template-columns: 1fr 1fr 1fr;
                            grid-gap: 2rem;
                            grid-template-rows: 32rem;
                            margin: 10rem 0rem 10rem 0rem;
                        }

                        .issue {
                            display: flex;
                            flex-direction: column;
                            @apply shadow-lg
                        }

                        .issue > img {
                            width: 20rem;
                            min-width: 20rem;
                            cursor: pointer;
                            min-height: 278px;
                            background: white;
                        }

                        .description {
                            margin-top: 1rem;
                            cursor: pointer;
                        }

                        @media(max-width: 500px) {
                            .issues-container {
                                align-items: center;
                                padding: 4rem;
                            }

                            .issues {
                                grid-template-columns: 1fr;
                                grid-template-rows: inherit;
                                margin: 2rem 0rem 2rem 0rem;
                            }

                            .issue > img {
                                object-fit: contain;
                                cursor: pointer;
                                width: 100%;
                            }
                        }
                    `}
                </style>
                <div
                    class="flex items-center w-full justify-center"
                    style={{
                        position: "relative",
                        height: "calc(50vh - 6rem)",
                        top: 0,
                        left: 0,
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        overflow: "hidden",
                    }}
                >
                    <h1
                        className="content-title"
                        style={{ color: "white", zIndex: 10, marginBottom: 0 }}
                    >
                        Issues
                    </h1>
                    <div
                        id="bg"
                        style={{
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundImage: `url('${issues[1].feature_image}')`,
                            position: "absolute",
                            height: "50vh",
                            top: 0,
                            left: 0,
                            width: "100%",
                        }}
                    />
                </div>
                <div className="container">
                    <div className="issues-container">
                        <div className="issues">
                            {/* <span>Issues</span> */}
                            {issues.map((i) => {
                                return (
                                    <div className="issue">
                                        <img
                                            src={`${i.og_image}`}
                                            className="shadow-lg"
                                            onClick={() =>
                                                navigate("/" + i.slug)
                                            }
                                            href={i.description}
                                        />
                                        <div
                                            class="description"
                                            onClick={() =>
                                                navigate("/" + i.slug)
                                            }
                                        >
                                            {i.name}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        {/* <div
                        id="bg"
                        style={{
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundImage: `url('${page.feature_image}')`,
                            position: "absolute",
                            height: "50vh",
                            top: 0,
                            left: 0,
                            width: "100%",
                        }}
                    />
                    <div
                        id="bg-spacer"
                        class="flex items-center w-full justify-center"
                        style={{
                            position: "relative",
                            height: "calc(50vh - 6rem)",
                            top: 0,
                            left: 0,
                            width: "100%",
                        }}
                    >
                        <h1
                            className="content-title"
                            style={{ color: "white" }}
                        >
                            {page.title}
                        </h1>
                    </div>

                    <article className="content">
                        {/* The main page content */}
                        {/* <section
                            className="content-body load-external-scripts"
                            dangerouslySetInnerHTML={{ __html: page.html }}
                        /> */}
                        {/* </article> */}
                    </div>
                </div>
            </Layout>
        </>
    );
};

Issues.propTypes = {
    data: PropTypes.shape({
        ghostPage: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
};

export default Issues;

export const issuesQuery = graphql`
    query GhostIssuesQuery {
        allGhostTag {
            nodes {
                slug
                name
                visibility
                feature_image
                description
                meta_title
                meta_description
                og_image
            }
        }
    }
`;
